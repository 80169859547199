import { useQuery } from "react-query";

export function useTopPodcasts() {
  return useQuery("getTopPodcasts", async () => {
    const response = await fetch("/.netlify/functions/getTopPodcasts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.json();
  });
}
