import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useTopPodcasts } from "hooks/useTopPodcasts";

const TopPodcasts = () => {
  const history = useHistory();
  const { status, data, error, isFetching } = useTopPodcasts();

  const handleGetPodcastDetail = (url) => (event) => {
    event.preventDefault();
    history.push(`/podcasts/${encodeURIComponent(url)}`);
  };

  return (
    <div>
      <h1>Top Podcasts</h1>
      <div className="top-podcasts">
        {data?.map((eachPodcast) => (
          <div
            key={eachPodcast.title}
            className="podcast-card"
            onClick={handleGetPodcastDetail(eachPodcast.rss, eachPodcast.id)}
          >
            <div className="img_holder">
              <img src={eachPodcast.cover.small} alt={eachPodcast.title} />
            </div>
            <div className="podcast-info">
              <div>{eachPodcast.title}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopPodcasts;
