import React from "react";
import { Switch, Route } from "react-router-dom";
import TopPodcasts from "components/TopPodcasts";
import PodcastDetail from "components/PodcastDetail";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={TopPodcasts} />
      <Route path="/podcasts/:rssUrl" component={PodcastDetail} />
    </Switch>
  );
};

export default Routes;
