import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useStore } from "store/index";

const PodcastPlayer = () => {
  const episodeInfo = useStore((store) => store.currentEpisode);

  return (
    <div
      style={{ position: "fixed", bottom: "0px", left: "0px", width: "100%" }}
    >
      <AudioPlayer
        autoPlay
        src={episodeInfo?.audio?.url || ""}
        onPlay={(e) => console.log("onPlay", e)}
        // other props here
      />
    </div>
  );
};

export default PodcastPlayer;
