import create from "zustand";
import { devtools } from "zustand/middleware";
import songData from "../data";

export const useStore = create(
  devtools((set, get) => ({
    songs: songData(),
    setSongs: (songs) =>
      set(() => ({
        songs,
      })),
    playList: [],
    setPlaylist: (data) =>
      set((state) => ({
        playList: [...state.playList, data],
      })),
    currentSong: songData()[0],
    setCurrentSong: (newSong) =>
      set((state) => ({
        currentSong: newSong,
      })),
    setCurrentSongById: (id) =>
      set((state) => ({
        currentSong: state.songs.filter((eachSong) => eachSong.id === id)[0],
      })),
    isPlaying: false,
    setIsPlaying: () =>
      set((state) => ({
        isPlaying: !state.isPlaying,
      })),
    songMetaInfo: {
      currentTime: 0,
      duration: 0,
      animationPercentage: 0,
    },
    setSongMetaInfo: (payload) =>
      set((state) => ({
        songMetaInfo: {
          ...state.songMetaInfo,
          ...payload,
        },
      })),
    libraryStatus: false,
    setLibraryStatus: () =>
      set((state) => ({
        libraryStatus: !state.libraryStatus,
      })),
    currentEpisode: {},
    setCurrentEpisode: (newEpisode) =>
      set((state) => ({
        currentEpisode: newEpisode,
      })),
  }))
);
