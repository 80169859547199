import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "store/index";
import { usePodcastData } from "hooks/usePodcastData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPlayCircle,
  faPauseCircle,
  faPause,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { getTime } from "utils/index";

const PodcastDetail = () => {
  const setCurrentEpisode = useStore((state) => state.setCurrentEpisode);
  const currentEpisode = useStore((state) => state.currentEpisode);
  const { rssUrl } = useParams();
  const { data, isLoading } = usePodcastData(rssUrl);

  const handlePlayEpisode = useCallback(
    (episodeInfo) => {
      setCurrentEpisode(episodeInfo);
    },
    [setCurrentEpisode]
  );

  return (
    <div>
      <h2>Podcast Detail</h2>
      {isLoading ? (
        <div>Loading</div>
      ) : (
        <div>
          <div className="podcast-info-wrapper">
            <img src={data?.meta?.image} alt={data?.meta?.title} />
            <div className="podcast-text-wrapper">
              <div>{data?.meta?.title}</div>
              <p
                dangerouslySetInnerHTML={{ __html: data?.meta?.description }}
              />
            </div>
          </div>
          <div className="episodes-list">
            {data?.episodes?.map((eachEpisode, index) => (
              <div
                className="episode-wrapper"
                key={`episode-${eachEpisode.title}-${index}`}
              >
                <FontAwesomeIcon
                  icon={
                    currentEpisode.title === eachEpisode.title
                      ? faPauseCircle
                      : faPlayCircle
                  }
                  size="3x"
                  className="play-pause-icon"
                  onClick={() => handlePlayEpisode(eachEpisode)}
                />
                <div className="episode-info">
                  <div className="episode-title">{eachEpisode.title}</div>
                  <div className="date-and-duration">
                    <div className="upload-date">
                      {new Date(eachEpisode.publishedDate).toDateString()}
                    </div>
                    <div className="duration">
                      {getTime(eachEpisode.duration)}
                    </div>
                  </div>
                  <p
                    className="episode-description"
                    dangerouslySetInnerHTML={{
                      __html: eachEpisode.description,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PodcastDetail;
