import { useQuery } from "react-query";

export function usePodcastData(url) {
  return useQuery("getPodcastData", async () => {
    const response = await fetch(
      `/.netlify/functions/getPodcastData?url=${url}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.json();
  });
}
