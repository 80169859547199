export const playPromise = (isPlaying, audioRef) => {
  if (isPlaying) {
    const playPromise = audioRef.current.play();
    if (playPromise !== undefined) {
      playPromise.then((audio) => {
        audioRef.current.play();
      });
    }
  }
};

const getColonNumber = (str) => {
  if (str && str.indexOf(":") > -1) {
    const splitArray = str.split(":");
    switch (splitArray.length - 1) {
      case 0:
        return str.concat("s");
      case 1:
        if (parseInt(splitArray[0]) === 0) return `${splitArray[1]}s`;
        return `$${splitArray[0]}m ${splitArray[1]}s`;
      case 2:
        if (parseInt(splitArray[0]) === 0)
          return `${splitArray[1]}m ${splitArray[2]}s`;
        return `${splitArray[0]}h ${splitArray[1]}m ${splitArray[2]}s`;
      default:
        return str.concat("s");
    }
  }
  return str;
};

export const getTime = (time) => {
  // Hours, minutes and seconds
  if (time && time.indexOf(":") > -1) {
    return getColonNumber(time);
  }

  const hrs = ~~(time / 3600);
  const mins = ~~((time % 3600) / 60);
  const secs = ~~time % 60;

  let ret = "";
  if (hrs > 0) {
    ret += "" + hrs + "h " + (mins < 10 ? "0" : "");
  }
  ret += "" + mins + "m " + (secs < 10 ? "0" : "");
  ret += "" + secs + "s ";
  return ret;
};
