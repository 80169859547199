import { useStore } from "store/index";
import { BrowserRouter as Router } from "react-router-dom";
import { useQuery } from "react-query";
import Player from "components/Player/index";

import Routes from "./routes";

function useCategories() {
  return useQuery("getCategories", async () => {
    const response = await fetch("/.netlify/functions/getPodcastCategories", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.json();
  });
}

function App() {
  const episodeInfo = useStore((store) => store.currentEpisode);
  const { status, data, error, isFetching } = useCategories();

  return (
    <div>
      <Router>
        <Routes />
      </Router>
      {episodeInfo && Object.keys(episodeInfo).length > 0 && <Player />}
    </div>
  );
}

export default App;
